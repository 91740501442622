import { Box, CardContent, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { StyledCard } from "../../modules/common/components/styled-card.component";
import { useTranslation } from "react-i18next";
import { EmissionFactorsTable } from "../../modules/emission-factors/components/emission-factors-table.component";
import { EmissionFactorsFilters } from "../../modules/emission-factors/components/emission-factors-filters.component";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import { NavItemUrlUtilities } from "../../modules/common/utilities/nav-item-url.utilities";
import {
  AppBreadCrumbs,
  IBreadCrumb,
} from "../../modules/common/components/app-bread-crumbs.component";

export const EmissionFactorsPage: FC = () => {
  const { t } = useTranslation("emission_factors_page");
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(`/organizations/${orgId}/configuration/emission-factors`);
            },
          }
        : undefined,
      navigationItems: {
        activePath: `/organizations/${organizationId}/configuration/emission-factors`,
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(`/organizations/${orgId}/configuration/emission-factors`);
            },
          }
        : undefined,
    }),
    [organizationId, navigate],
  );
  useCustomTopBarContent(topBarConfiguration);

  const [searchParams, setSearchParams] = useSearchParams();

  if (!organizationId) {
    return null;
  }

  const breadCrumbItems: IBreadCrumb[] = [
    {
      label: t("configuration", { ns: "side_bar_nav_items" }),
      to: NavItemUrlUtilities.getConfigurationPageUrl(organizationId),
    },
    { label: t("configuration_emission_factors", { ns: "side_bar_nav_items" }) },
  ];

  return (
    <Box>
      <AppBreadCrumbs items={breadCrumbItems} />
      <Typography variant="h1" mt={1}>
        {t("title")}
      </Typography>
      <Box mt={2.5}>
        <EmissionFactorsFilters searchParams={searchParams} setSearchParams={setSearchParams} />
      </Box>
      <Box mt={4}>
        <StyledCard>
          <CardContent sx={{ p: 2.5 }}>
            <EmissionFactorsTable
              organizationId={organizationId}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </CardContent>
        </StyledCard>
      </Box>
    </Box>
  );
};
