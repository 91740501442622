import { FC } from "react";
import {
  Box,
  Chip,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { useGetEmissionFactorsQuery } from "../emission-factors.queries";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";
import {
  IEmissionFactorDatabase,
  IEmissionFactorType,
  IEmissionFactorUnit,
  IGhgCategoriesIdentifier,
} from "@netcero/netcero-core-api-client";
import {
  CountryCodeAlpha2Enum,
  IGhgProtocolScope,
  MappingUtilities,
} from "@netcero/netcero-common";
import { EmissionFactorsFilter } from "../../filters/filter.constants";
import { CornerRightUpDoubleIcon } from "../../common/constants/tabler-icon.constants";
import { SetURLSearchParams } from "react-router-dom";
import { PaginationUtilities } from "../../pagination/pagination.utilities";
import { TablePaginationForState } from "../../pagination/components/table-pagination-for-state.component";
import { useSearchParamsPaginationState } from "../../pagination/hooks/query-params-pagination-state.hook";
import { GhgCategoriesChips } from "./ghg-category-chip.component";
import { FormatUtilities } from "../../common/utilities/format.utilities";

interface IEmissionFactorsTableProps {
  organizationId: string;
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
}

export const EmissionFactorsTable: FC<IEmissionFactorsTableProps> = ({
  organizationId,
  searchParams,
  setSearchParams,
}) => {
  const { t } = useTranslation("emission_factors_table");
  const translateContent = useTranslateContent();

  const paginationState = useSearchParamsPaginationState(searchParams, setSearchParams);

  const emissionFactorsQuery = useGetEmissionFactorsQuery({
    organizationId,
    ...PaginationUtilities.getPaginationQueryParams(paginationState.state),
    title: searchParams.get(EmissionFactorsFilter.name) ?? undefined,
    emissionFactorDatabases: searchParams.getAll(
      EmissionFactorsFilter.databases,
    ) as IEmissionFactorDatabase[],
    emissionFactorTypes: searchParams.getAll(
      EmissionFactorsFilter.emissionFactorTypes,
    ) as IEmissionFactorType[],
    ghgCategories: searchParams.getAll(
      EmissionFactorsFilter.ghgCategories,
    ) as IGhgCategoriesIdentifier[],
    countries: searchParams.getAll(EmissionFactorsFilter.countries) as CountryCodeAlpha2Enum[],
    units: searchParams.getAll(EmissionFactorsFilter.units) as IEmissionFactorUnit[],
  });

  return (
    <QueryWrapper
      query={emissionFactorsQuery}
      build={({ items, total }) =>
        items.length === 0 ? (
          <Box>{t("no_emission_factors_found")}</Box>
        ) : (
          <>
            <Table size="small" sx={{ mt: -2 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell width="33%">{t("name")}</StyledTableCell>
                  <StyledTableCell>{t("database")}</StyledTableCell>
                  <StyledTableCell>{t("type")}</StyledTableCell>
                  <StyledTableCell>{t("version")}</StyledTableCell>
                  <StyledTableCell>{t("ghg_category")}</StyledTableCell>
                  <StyledTableCell>{t("country")}</StyledTableCell>
                  <StyledTableCell>{t("factor")}</StyledTableCell>
                  <StyledTableCell>{t("unit")}</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {items?.map(
                  ({
                    id,
                    title,
                    database,
                    emissionFactorType,
                    version,
                    ghgCategories,
                    factor,
                    scopeFactors,
                    country,
                    unit,
                  }) => (
                    <TableRow key={id}>
                      <StyledTableCell width="33%">{translateContent(title)}</StyledTableCell>
                      <StyledTableCell>
                        {t(database, { ns: "emission_factor_databases" })}
                      </StyledTableCell>
                      <StyledTableCell>
                        {t(emissionFactorType, { ns: "emission_factor_types" })}
                      </StyledTableCell>
                      <StyledTableCell>{version}</StyledTableCell>
                      <StyledTableCell>
                        <GhgCategoriesChips ghgCategories={ghgCategories} />
                      </StyledTableCell>
                      <StyledTableCell>
                        {FormatUtilities.formatOptionalString(
                          MappingUtilities.mapIfNotUndefined(country, (c) =>
                            t(`countries.${c}`, { ns: "common" }),
                          ),
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Stack direction="row" alignItems="center" gap={0.5}>
                          <Box minWidth="40px">{factor}</Box>
                          <Chip size="small" label="kgCO₂eq" sx={{ ml: 0.5 }} />
                          {scopeFactors[IGhgProtocolScope.Scope3] !== undefined ? (
                            <Tooltip title={`${scopeFactors[IGhgProtocolScope.Scope3]} kgCO₂eq`}>
                              <CornerRightUpDoubleIcon />
                            </Tooltip>
                          ) : (
                            <Box width={5} />
                          )}
                        </Stack>
                      </StyledTableCell>
                      <StyledTableCell>{t(unit, { ns: "emission_factor_units" })}</StyledTableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
            <TablePaginationForState
              component="div"
              sx={{ mt: 1 }}
              count={total}
              paginationStateResult={paginationState}
            />
          </>
        )
      }
    />
  );
};

const StyledTableCell = styled(TableCell)({
  padding: "16px",
});
