export class NavItemUrlUtilities {
  public static getOrganizationPageUrl(organizationId: string) {
    return `/organizations/${organizationId}`;
  }

  public static getRecordingPeriodPageUrl(organizationId: string) {
    return `/organizations/${organizationId}/recording-periods`;
  }

  public static getRecordingPeriodDashboardPageUrl(
    organizationId: string,
    recordingPeriodId: string,
  ) {
    return `/organizations/${organizationId}/recording-periods/${recordingPeriodId}/all`;
  }

  public static getFilteredRecordingPeriodDashboardPageUrl(
    organizationId: string,
    recordingPeriodId: string,
    recordingStructureType?: string,
  ) {
    return `/organizations/${organizationId}/recording-periods/${recordingPeriodId}/${
      recordingStructureType ?? "all"
    }`;
  }

  public static getRecordingStructurePageUrl(
    organizationId: string,
    recordingPeriodId: string,
    recordingStructureType: string,
    recordingStructureId: string,
  ) {
    return `/organizations/${organizationId}/recording-periods/${recordingPeriodId}/${recordingStructureType}/recording-structures/${recordingStructureId}`;
  }

  public static getDeoPageUrl(
    organizationId: string,
    recordingPeriodId: string,
    recordingStructureType: string,
    recordingStructureId: string,
    dataEntryObjectId: string,
  ) {
    return `/organizations/${organizationId}/recording-periods/${recordingPeriodId}/${recordingStructureType}/recording-structures/${recordingStructureId}/data-entry-objects/${dataEntryObjectId}/`;
  }

  public static getEvaluationSelectDataEntryObjectPageUrl(
    organizationId: string,
    recordingPeriodId: string,
    evaluationKey: string,
    recordingStructureId: string,
    dataEntryObjectId: string,
  ) {
    return `/organizations/${organizationId}/recording-periods/${recordingPeriodId}/evaluations/${evaluationKey}/recording-structures/${recordingStructureId}/data-entry-objects/${dataEntryObjectId}`;
  }

  public static getEmissionsEvaluationPageUrl(
    organizationId: string,
    recordingPeriodId: string,
    recordingStructureId: string,
    dataEntryObjectId: string,
  ) {
    return `/organizations/${organizationId}/recording-periods/${recordingPeriodId}/evaluations/emissions/recording-structures/${recordingStructureId}/data-entry-objects/${dataEntryObjectId}`;
  }

  public static getDMAPageUrl(organizationId: string, recordingPeriodId: string) {
    return `/organizations/${organizationId}/recording-periods/${recordingPeriodId}/double-materiality-assessment`;
  }

  public static getDMADashboardPageUrl(organizationId: string, recordingPeriodId: string) {
    return `/organizations/${organizationId}/recording-periods/${recordingPeriodId}/double-materiality-assessment/dashboard`;
  }

  public static getTargetPageUrl(organizationId: string) {
    return `/organizations/${organizationId}/target-paths`;
  }

  public static getReferenceBudgetsPageUrl(organizationId: string) {
    return `/organizations/${organizationId}/target-paths/reference-budgets`;
  }

  public static getConfigurationPageUrl(organizationId: string) {
    return `/organizations/${organizationId}/configuration`;
  }

  public static getAuditLoggingConfigurationPageUrl(organizationId: string) {
    return `/organizations/${organizationId}/configuration/audit-logging`;
  }

  public static getConfigurationRecordingPeriodPageUrl(organizationId: string) {
    return `/organizations/${organizationId}/configuration/recording-periods`;
  }

  public static getConfigurationStakeholderPageUrl(organizationId: string) {
    return `/organizations/${organizationId}/configuration/stakeholder`;
  }

  public static getConfigurationDistributionCriteriaPageUrl(organizationId: string) {
    return `/organizations/${organizationId}/configuration/distribution-criteria`;
  }

  public static getConfigurationSourcesPageUrl(organizationId: string) {
    return `/organizations/${organizationId}/configuration/sources`;
  }

  public static getConfigurationUsersPageUrl(organizationId: string) {
    return `/organizations/${organizationId}/configuration/users`;
  }

  public static getConfigurationEmissionFactorsPageUrl(organizationId: string) {
    return `/organizations/${organizationId}/configuration/emission-factors`;
  }
}
