import { IGhgCategoriesIdentifier } from "@netcero/netcero-core-api-client";

export const GHG_CATEGORIES_PATHS: Record<IGhgCategoriesIdentifier, string> = {
  [IGhgCategoriesIdentifier._11StationaryCombustion]: "1.1",
  [IGhgCategoriesIdentifier._12MobileCombustion]: "1.2",
  [IGhgCategoriesIdentifier._13FugitiveEmissions]: "1.3",
  [IGhgCategoriesIdentifier._21PurchasedElectricity]: "2.1",
  [IGhgCategoriesIdentifier._22PurchasedHeat]: "2.2",
  [IGhgCategoriesIdentifier._23PurchasedSteam]: "2.3",
  [IGhgCategoriesIdentifier._24PurchasedCooling]: "2.4",
  [IGhgCategoriesIdentifier._31PurchasedGoodsAndServices]: "3.1",
  [IGhgCategoriesIdentifier._32CapitalGoods]: "3.2",
  [IGhgCategoriesIdentifier._33EnergyRelatedActivitiesNotInScope1And2]: "3.3",
  [IGhgCategoriesIdentifier._34UpstreamTransportationAndDistribution]: "3.4",
  [IGhgCategoriesIdentifier._35Waste]: "3.5",
  [IGhgCategoriesIdentifier._36BusinessTravel]: "3.6",
  [IGhgCategoriesIdentifier._37EmployeeCommuting]: "3.7",
  [IGhgCategoriesIdentifier._38LeasedOrRentedAssets]: "3.8",
  [IGhgCategoriesIdentifier._39DownstreamTransportationAndDistribution]: "3.9",
  [IGhgCategoriesIdentifier._310ProcessingOfSoldProducts]: "3.10",
  [IGhgCategoriesIdentifier._311UseOfSoldProducts]: "3.11",
  [IGhgCategoriesIdentifier._312EndOfLifeTreatmentOfSoldProducts]: "3.12",
  [IGhgCategoriesIdentifier._313LeasedOrRentedAssetsDownstream]: "3.13",
  [IGhgCategoriesIdentifier._314Franchise]: "3.14",
  [IGhgCategoriesIdentifier._315Investments]: "3.15",
};
