import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { IGhgCategoriesIdentifier } from "@netcero/netcero-core-api-client";
import { GHG_CATEGORIES_PATHS } from "./emission-factors.constants";

export const useRenderGhgCategoryName = () => {
  const { t } = useTranslation("ghg_categories");

  return useCallback(
    (ghgCategory: IGhgCategoriesIdentifier) => {
      return `${GHG_CATEGORIES_PATHS[ghgCategory]} ${t(ghgCategory)}`;
    },
    [t],
  );
};
